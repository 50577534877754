import { SnackBarService } from './../../core/services/snack-bar.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/services/environment.service';
import { FlowQueue, FlowQueueObject, FlowStateObject } from '../entities/workflow.d';

@Injectable({
  providedIn: 'root'
})
export class FlowQueueService {

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private snack: SnackBarService
    ) { 
      this.httpTimeout = env.httpTimeout ?? 2000;
    }

  public httpTimeout!: number;
  public queues!: FlowQueue[];
  public queueId: string = '';
  public queueObjects!: FlowQueueObject[];

  public getQueues(fromServer:boolean = false, queueList?: string[]): Observable<FlowQueue[]> {
      this.queues = [];

      return this.http.put<any>(this.env.apiUrl + '/workflow/queues/getList', { ids: queueList }, { headers: this.env.apiHeaders })
      .pipe(
        map(response => {
          this.queues = [];
          if (response.status == 'success' && response.queues) {
            this.queues = response.queues;
          }
          return this.queues;
        }))
      .pipe(timeout(2000),
      catchError(e => {
        console.error(e);
        this.queues = [];
        return of(this.queues);
      }));
  }

  public addQueue(name:string = 'NEW QUEUE', id?: string): Observable<FlowQueue | undefined> {
      return this.http.put<any>(this.env.apiUrl + `/workflow/queue/${id??''}`, { name: name}, { headers: this.env.apiHeaders })
      .pipe(
        map(response => {
          if (response.status != 'success') {
            console.error('Server Error on addQueue');
          }
          return response.data;
        }))
      .pipe(timeout(2000),
      catchError(e => {
        // do something on a timeout
        return of(undefined);
      }));
  }

  public deleteQueue(queueId:string): Observable<any> { 
      return this.http.put<any>(this.env.apiUrl + `/workflow/queue/${queueId}/delete`, '', { headers: this.env.apiHeaders })
      .pipe(
        map(response => {
          if (response.status != 'success') {
            console.error('Server Error on deleteQueue');
          }
          return response;
        }))
      .pipe(timeout(2000),
      catchError(e => {
        // do something on a timeout
        this.queueObjects = [];
        return of(undefined);
      }));
  }

  public actQueue(queueId:string, action: string): Observable<any> { 
      return this.http.put<any>(this.env.apiUrl + `/workflow/queue/${queueId}/${action}`, '', { headers: this.env.apiHeaders })
      .pipe(
        map(response => {
          if (response.status != 'success') {
            console.error(`Server Error on actQueue ${action}`);
          }
          return response;
        }))
      .pipe(timeout(2000),
      catchError(e => {
        // do something on a timeout
        return of(undefined);
      }));
  }

  public pushStateToQueue(queueId:string, state: FlowStateObject, additionalQueues: string[]): Observable<any> { 

    let stateId = state.id ?? '';
    return this.http.put<any>(this.env.apiUrl + `/workflow/queue/${queueId}/push/${stateId}`, { state: state, additionalQueues: additionalQueues }, { headers: this.env.apiHeaders })
    .pipe(
      map(response => {
        if (response.status != 'success') {
          console.error(`Server Error on actQueue push ${response.status}`);
        }
        return response;
      }))
    .pipe(timeout(20000),
    catchError(e => {
      if (e.status && e.error){
        this.snack.openSnackBar(`Error ${e.status}: ${e.error}`, 'OK');  
      }
      return of({undefined});
    }));
  }  

  public getQueueObjects(queueId: string, stateId?: string): Observable<FlowQueueObject[]> { // Note: this is a put, not a get
    // if (!this.queueObjects || this.queueId != queueId) {
      this.queueId = '';
      this.queueObjects = [];


      return this.http.put<any>(this.env.apiUrl + `/workflow/queue/${queueId}/read`, '', { headers: this.env.apiHeaders })
      .pipe(
        map(response => {
          this.queues = [];
          if (response.status == 'success' && response.data) {
            this.queueObjects = response.data;
            this.queueId = queueId;
          }
          return this.queueObjects;
        }))
      .pipe(timeout(2000),
      catchError(e => {
        // do something on a timeout
        this.queueObjects = [];
        return of(this.queueObjects);
      }));
    // } else {
    //   return of(this.queueObjects);
    // }
  }

  public actQueueObject(queueId:string, stateId:string, action: string = 'get', state: any = "{}"): Observable<FlowQueueObject | undefined> { 
    return this.http.put<any>(this.env.apiUrl + `/workflow/queue/${queueId}/state/${stateId}/${action}`, JSON.parse(state), { headers: this.env.apiHeaders })
    .pipe(
      map(response => {
        if (response.status != 'success') {
          console.error(`Server Error on actQueue ${action}`);
          return of(undefined);
        }
        return response.data;
      }))
    .pipe(timeout(2000),
    catchError(e => {
      // do something on a timeout
      return of(undefined);
    }));
  }
}
