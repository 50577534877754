import { CreditCardProduct } from './../modules/credit/entities/credit.d';
import { Injectable } from '@angular/core';
import { config } from "../../environments/tenant";
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  
  constructor() {  }

  public get tenantId() {
    return config.tenantId ?? 'default';
  }

  public get tenantName() {
    return config.tenantName;
  }

  public get tenantAcronym() {
    return config.tenantAcronym;
  }

  public get corpLogoUrl() {
    return config.corpLogoUrl;
  }

  public get tenantSubsidiaries() {
    return config.tenantSubsidiaries;
  }

  public get tenantEmailSuffix() {
    return config.tenantEmailSuffix;
  } 

  public getTenantCardImageUrl(product: number) {
    let products = config.tenantCardProducts;
    let url = '';
    products.forEach((item) => {
      if (item.id == product) {
        url = item.imageUrl ?? '';
      }
    });
    return url;
  }

  public getTenantCardProducts(): CreditCardProduct[] {
    return config.tenantCardProducts;
  }

  public get httpTimeout(): number {
    return config.httpTimeout;
  }

  public get apiUrl(): string {
    return config.apiUrl;
  }

  public get apiHeaders(): any {
    return {
      'apikey': config.apikey,
      'apiversion': config.apiversion
    };  
  }

  public get apiBaseConsole(): string {
    return config.apiBaseConsole;
  }

  public get embosserServiceName(): string {
    return config.tenantAPIs.embossing.name || '';
  }

  public get tenantAddress() {
    return config.tenantAddress || [];
  }

  public get serviceCardParms() {
    return config.serviceCardParms;
  }

  public get dict() {
    return config.dict;
  }

  public get tenantRegions() {
    return config.tenantRegions;
  }

  public get tenantStores() {
    return config.tenantStores;
  }

  public getTenantStoresByRegion(region: string) {
    return config.tenantStores.filter((item,i) => item.region && item.region == region );
  }


  public get tenantActions() {
    return config.tenantActions;
  }

  public get tenantInsuranceProducts() {
    return config.tenantInsuranceProducts;
  }

  public get custInLocate() {
    return config.tenantParams?.includeCustInLocate ?? true;
  }
  
  public get toggleNameSearch() {
    return config.tenantParams?.toggleNameSearch ?? true; 
  }

  public get showActionCodeValue() {
    return config.tenantParams?.showActionCodeValue ?? true; 
  }

  public get newAPI() {
    return config.tenantParams?.newAPI ?? true;
  }

  public get useLocalCache() {
    return config.tenantParams?.useLocalCache ?? false;
  }

  public get language() {
    return config.tenantParams?.language ?? 'en_us';
  }

}
