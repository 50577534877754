import { EnvironmentService } from 'src/app/services/environment.service';
import { environment  } from "../../environments/environment";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Suburb } from "../modules/core/entities/core";

@Injectable({providedIn: 'root'})

export class CatalogService {
  private _suburbs!: Suburb[];
  // public get currentUser() {
  //   return this.getUser();
  // }

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {}

  search(zip: number) {
    this._suburbs = [];
    return this.http.get<any>(this.env.apiUrl + '/catalog/suburbs/' + zip, { headers: this.env.apiHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response
        if (response.status == 'success' && response.suburbs && response.suburbs.length > 0) {
          this._suburbs = response.suburbs;
        } else {
        }
        return this._suburbs;
      }));    
  }
}