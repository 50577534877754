// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'lp-demo-003',
    appId: '1:868831403883:web:918a8510a3eec2280a762e',
    storageBucket: 'lp-demo-003.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyBcgOqyo2k0N59IO_IZ9-iGN-auQ7LWlOo',
    authDomain: 'lp-demo-003.firebaseapp.com',
    messagingSenderId: '868831403883',
    measurementId: 'G-XMKH1J1TTQ',
  },
  production: false,
  httpTimeout: 2000
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
