import { EnvironmentService } from 'src/app/services/environment.service';
import { LoginUser } from '../modules/core/entities/core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { getAuth } from '@angular/fire/auth';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { of } from 'rxjs';

const usingFirebaseAuth = true;

@Injectable({providedIn: 'root'})
export class AuthService {
  private _user!: LoginUser;

  constructor(  
    private http: HttpClient,
    private env: EnvironmentService
  ) {}

  public login(username: string, password: string, useToken: boolean = false) {
    return this.doLogin(username, password, useToken);
  }

  public loginOverride(username: string, password: string, useToken: boolean = false, onSuccess: Function, onFailure: Function) {
    return this.doLogin(username, password, useToken, true, onSuccess, onFailure);
  }

  private doLogin(username: string, password: string, useToken: boolean = false, override: boolean = false, onSuccess?: Function, onFailure?: Function) {
    let body: any = { "name": username, "useToken": useToken };
    if (!useToken) {
      body.passHash = password;
    } else {
      body.idToken = password;
    }
    return this.http.post<any>(this.env.apiUrl + '/login/login', body, { headers: this.env.apiHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response
        if (response.status == 'success' && response.user && response.user.authToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (onSuccess) {
            onSuccess(response);
          } 
          if (!override) {
            this._user = response.user;
            localStorage.setItem('isLoggedIn', JSON.stringify(true));
            localStorage.setItem('currentUser', JSON.stringify(this.user));
            localStorage.setItem('auth_token', JSON.stringify(response.user.authToken));
          }
        } else {
          if (onFailure) {
            onFailure(response);
          }
        }
        return response.user;
      }));
  }

  logout() { 
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('auth_token');
    localStorage.removeItem('isLoggedIn');
  }

  public get user(): LoginUser {
    let localUser = localStorage.getItem("currentUser");
    if (!this._user && localUser) {
      this._user = JSON.parse(localUser);
    }
    return this._user; 
  }

  public get isLoggedIn() {
    if (localStorage.getItem("isLoggedIn") == null) {
      return false;
    }
    return true;
  }

  public hasAccess(resourceId: string, permission: string): boolean {
    let result = false;
    if (this.isLoggedIn && this.user.permissions){
      this.user.permissions.forEach(perm => {
        if (perm.resourceId == resourceId && perm.grant == permission) {
          result = true;
        }
      });
    }
    return result;
  }

  selectQueues(action: string, tags?: object) {
    let queues: string[] = [];
    switch(action) {
      case "account": {
        queues.push("user:"+this.user.name);
        this.user.profile?.teamMemberships?.forEach(team => {
          queues.push("team:"+team);
        })
        break;
      }
    }
    return queues;
  }
}