import { EnvironmentService } from 'src/app/services/environment.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const updateInterval = 30;
const updateIntervalPaused = 300;

export interface CoreActionData {
  actionClass          ?: string, // ACTIONCODE
  actionId             ?: string, // EMBO, NOTA, M456 etc
  uniqueLogId          ?: string, // unique logging identifier for action
  creditCustomerNumber ?: string,
  creditAccountNumber  ?: string,
  creditCardNumber     ?: string,
  creditOrg            ?: string
}

export interface CoreTimer {
  id: string,

  // timestamps
  start: number,
  end: number,
  lastSync: number,

  // other
  elapsedSeconds: number,
  realTimeSeconds: number,
  running: boolean,
  // local
  elapsedInterval?: number,

}

export enum TimerAction {
  Start = 1,
  Stop,
  Pause,
  Resume,
  Delete
}

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  // public baseUri: string = 'http://34.70.151.44/api/core';
  public baseUri: string = this.env.apiUrl + '/core';
  public apiKey: string = '';
  public token: string = '';

  public timers!: Map<string, CoreTimer>;
  public idCount: number = 0;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService) { 
    this.timers = new Map<string, CoreTimer>();
  }

  private timerDo(localId: string, onSuccess: (timer: CoreTimer)=>void = ()=>{}, action: TimerAction): boolean {
    let timer = this.timers.get(localId);

    let actionString = '';
    switch (action) {
      case TimerAction.Start : actionString = '/start' ; break;
      case TimerAction.Stop  : actionString = '/stop'  ; break;
      case TimerAction.Pause : actionString = '/pause' ; break;
      case TimerAction.Resume: actionString = '/resume'; break;
      case TimerAction.Delete: actionString = '/delete'; break;
    }

    if (!timer || !timer.id) {
      console.error('Invalid ID for '+actionString+' timer call: ' + localId);
      // console.log(this.timers);
      return false;
    }

    let success = (response: HttpResponse<any>) => {
      if (response.status == 200  && response.body.status == "success") {
        this.timers.set(localId, response.body.data);
      }
      onSuccess(response.body.data);
    };

    let failure = (response: any) => {
      console.error('start/stop timer Call Failed');
      console.error(response);
    };
   
    this.callService('timer/' + timer?.id + actionString, '', success, failure, 'GET').subscribe((response) => {
      // console.log(response);
    });

    return true;
  }

  stop(localId: string, onSuccess: (timer: CoreTimer)=>void = ()=>{}): boolean {
    return this.timerDo(localId, onSuccess, TimerAction.Stop);
  }
  start(localId: string, onSuccess: (timer: CoreTimer)=>void = ()=>{}): boolean {
    return this.timerDo(localId, onSuccess, TimerAction.Start);
  }
  pause(localId: string, onSuccess: (timer: CoreTimer)=>void = ()=>{}): boolean {
    return this.timerDo(localId, onSuccess, TimerAction.Pause);
  }
  resume(localId: string, onSuccess: (timer: CoreTimer)=>void = ()=>{}): boolean {
    return this.timerDo(localId, onSuccess, TimerAction.Resume);
  }
  delete(localId: string, onSuccess: (timer: CoreTimer)=>void = ()=>{}): boolean {
    return this.timerDo(localId, onSuccess, TimerAction.Resume);
  }

  getTimer(localId: string, onSuccess: (timer: any)=>void = ()=>{}, tick: boolean = false): number {
    let timer = this.timers.get(localId);

    if (!timer || !timer.id) {
      console.error('Invalid ID for get timer call: ' + localId);
      return 0;
    }

    if (!timer.elapsedInterval) {
      timer.elapsedInterval = 0;
    };
    timer.elapsedInterval += 1;

    if (timer.running && tick ) {
      timer.elapsedSeconds += 1;
    }

    this.timers.set(localId, timer);
    onSuccess(this.timers.get(localId));

    if ((timer.running && timer.elapsedInterval >= updateInterval) || (!timer.running && timer.elapsedInterval >= updateIntervalPaused)) {
      timer.elapsedInterval = 0;
      let success = (response: HttpResponse<any>) => {
        if (response.status == 200  && response.body.status == "success") {
          this.timers.set(localId, response.body.data);
        }
      };
  
      let failure = (response: any) => {
        console.error('getTimer Call Failed');
        console.error(response);
      };
     
      this.callService('timer/' + timer?.id, '', success, failure, 'GET').subscribe((response) => {
      });
    } else {
      onSuccess(timer);
    }

    return 0;
  }

  create(startRunning: boolean = false, actionData: CoreActionData, onSuccess: (id: string)=>void = ()=>{}, remoteId = '') {
    let body = {
      "actionData": actionData,
      "running": startRunning
    };

    this.idCount += 1;
    let localId = 'LOC-' + this.idCount;

    let success = (response: HttpResponse<any>) => {
      this.timers.set(localId, response.body.data);

      // console.log(this.timers);

      onSuccess(localId);
    };

    let failure = (response: any) => {
      console.error('Timer Create Call Failed');
      console.error(response);
    };
    /** Call GET to fetch existing timer, if remoteId is provided */
    let getFail = () => { console.error('Error getting timer'); };
    if (remoteId != '') {
      getFail = () => {
        this.callService('timer', body, success, failure, 'PUT').subscribe();
      } 
      this.callService('timer/' + remoteId, '', success, getFail, 'GET').subscribe();  
    } else {
      this.callService('timer', body, success, failure, 'PUT').subscribe();
    }

    return localId;
  }

  callService(endPoint: string, body: any, onSuccess: (response: HttpResponse<any>) => void, onFailure: (response: any) => void, method: string = 'POST') {
    // console.log('TIMER', endPoint, this.env.apiHeaders, body, method);
    switch (method) {
      case 'GET':
        return this.http.get<any>(this.baseUri + '/' + endPoint, { headers: this.env.apiHeaders, observe: 'response' })
          .pipe(map(response => {
            if (response.status == 200) {
              onSuccess(response);
              // console.log(response);
            } else {
              console.error('failed api call: ');
              // onFailure(response);
            }
          }));
      case 'PUT':
        return this.http.put<any>(this.baseUri + '/' + endPoint, body, { headers: this.env.apiHeaders, observe: 'response' },)
          .pipe(
            // catchError(this.handleError),
            map(response => {
            if (response.status == 200) {
              onSuccess(response);
            } else {
              console.error('failed api call: ');
              onFailure(response);
            }
          }));
      default:
        return this.http.post<any>(this.baseUri + '/' + endPoint, body, { headers: this.env.apiHeaders, observe: 'response' },)
          .pipe(
            // catchError(this.handleError),
            map(response => {
            if (response.status == 200) {
              onSuccess(response);
            } else {
              console.error('failed api call: ');
              onFailure(response);
            }
          }));
    }
  }

  handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
